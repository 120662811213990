import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, white, gray, green, orange } from 'src/styles/colors'

export const ContainerSimulator = styled.div`
  background: ${white};
  padding: 40px 24px 24px 24px;
  min-height: 582px;
  border-radius: 14px;
  border: 1px solid ${grayscale[200]};
  box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.10);

  .cifra {
    color: ${orange.extra};
    display: block;
    font-size: 28px;
    margin-bottom: 1px;
    margin-bottom: 32px;

    @media ${device.tablet} {
      font-size: 22px;
    }
    @media ${device.desktopXL} {
      font-size: 28px;
    }

    .line-bottom {
      border-bottom: 1px solid ${grayscale[300]};

      input {
        border: 0;
        outline: none;
        overflow: hidden;
        background-color: ${white};
        color: ${orange.extra};
      }
    }
  }

  .range-slider {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: 100%;
    z-index: 0;

    & > input {
      cursor: grab;
      opacity: 0;
      position: relative;
      width: 100%;
      z-index: 5;       

      &::-webkit-slider-thumb {
        appearance: none;
        border-radius: 4px;
        height: 30px;
        width: 50px;
        position: relative;
        z-index: 100;
      }
    }

    & > span.slider-container {
      box-sizing: border-box;
      display: inline-block;
      left: -20px;
      right: 46px;
      position: absolute;
      z-index: 3;

      & > span.bar {
          background-color: ${gray[500]};
          border-radius: 4px;
          display: inline-block;
          left: 21px;
          height: 12px;
          overflow: hidden;
          position: absolute;
          right: -42px;
          top: 5px;
          z-index: 1;
        & > span {
          background: ${green[400]};
          display: block;
          float: left;
          height: 11px;
        }
      }
      & > span.bar-btn {
        display: inline-block;
        background-color: ${grayscale[400]};
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        left: 22px;
        height: 11px;
        position: absolute;
        top: 5px;
        z-index: 3;
      }
    }
  }

  .simulator-header {
    @media ${device.tablet} {
      width: 72%;
      margin: 0 auto;
    }
  }
`

export const PeriodoValorFinal = styled.div`
  background: ${grayscale[100]};
  border-radius: 4px;
  padding: 6px;
  height: 37px;
  margin: 16px 0;
`

export const InputRange = styled.div`
  margin-bottom: 8px;
  overflow: hidden;
`

export const TypeList = styled.ul`
  margin-bottom: 8px;
  color: ${grayscale[500]};
  width: 100%;
  list-style: none;
  padding-left: 0;

  @media ${device.desktopLG} {
    display: flex;
  }

  li {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17px;

    @media ${device.desktopLG} {
      margin-right: 24px;
    }

    span {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: ${grayscale[400]};
      margin-right: 8px;
      float: left;

      &.green {
        background: ${green[400]};
      }
    }
  }
`
